<template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
      <!-- Card stats -->
    </base-header>

    <b-container fluid class="mt--7">
      <b-row>
        <b-col>
          <b-overlay :show="loading" rounded="md">
            <b-card no-body>
              <b-card-header class="border-1">
                <h3 class="mb-0 d-inline">{{ pageName }}</h3>
              </b-card-header>
              <b-card-body>
                <div>
                  <b-row>
                    <b-col>
                      <h6 class="heading-small text-muted">履歴情報</h6>
                    </b-col>
                  </b-row>
                  <b-table-simple>
                    <b-tbody>
                      <b-tr>
                        <b-th>導入日時</b-th>
                        <b-td>{{ showDatetime(upload_history.created_dt) }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th>導入者</b-th>
                        <b-td>{{ upload_history.user_name }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                  <b-row class="mt-4">
                    <b-col cols="12">
                      <h6 class="heading-small text-muted">給与明細書一覧({{ upload_history.payment_slip_count }}件)</h6>
                    </b-col>
                    <b-col cols="12">
                      <b-table
                        :items="paymentSlipFiles"
                        :fields="fields"
                        :current-page="currentPage1"
                        :per-page="perPage"
                        stacked="md"
                        show-empty
                        small
                        hover
                      >
                        <template #cell(is_sent)="row">
                          <b-icon v-if="row.value" icon="check-circle-fill" variant="success"></b-icon>
                          <b-icon v-else icon="x-circle-fill" variant="danger"></b-icon>
                        </template>
                        <template #cell(file_name)="row">
                          <a href="javascript:void(0)" @click="previewPdf(row.item.file_uuid)">
                            {{ row.value }}
                          </a>
                        </template>
                      </b-table>
                      <b-pagination
                        v-model="currentPage1"
                        :total-rows="totalRows1"
                        :per-page="perPage"
                        size="md"
                        class="mt-3"
                      ></b-pagination>
                    </b-col>
                  </b-row>
                  <b-row class="mt-4">
                    <b-col cols="12">
                      <h6 class="heading-small text-muted">源泉票一覧({{ upload_history.withholding_count }}件)</h6>
                    </b-col>
                    <b-col cols="12">
                      <b-table
                        :items="withholdingFiels"
                        :fields="fields"
                        :current-page="currentPage2"
                        :per-page="perPage"
                        stacked="md"
                        show-empty
                        small
                        hover
                      >
                        <template #cell(is_sent)="row">
                          <b-icon v-if="row.value" icon="check-circle-fill" variant="success"></b-icon>
                          <b-icon v-else icon="x-circle-fill" variant="danger"></b-icon>
                        </template>
                        <template #cell(file_name)="row">
                          <a href="javascript:void(0)" @click="previewPdf(row.item.file_uuid)">
                            {{ row.value }}
                          </a>
                        </template>
                      </b-table>
                      <b-pagination
                        v-model="currentPage2"
                        :total-rows="totalRows2"
                        :per-page="perPage"
                        size="md"
                        class="mt-3"
                      ></b-pagination>
                    </b-col>
                  </b-row>
                </div>
              </b-card-body>
              <b-card-footer>
                <b-row>
                  <b-col cols="12" class="d-flex justify-content-between">
                    <span>
                      <b-button
                        v-if="canResendAll"
                        variant="primary"
                        v-b-tooltip
                        title="未送信のファイルを一斉送信"
                        @click="resend"
                      >
                        送信&nbsp;&nbsp;<i class="far fa-paper-plane"></i>
                      </b-button>
                    </span>
                    <span>
                      <b-button variant="dark" :to="{ name: 'expense-upload-list' }">戻る</b-button>
                    </span>
                  </b-col>
                </b-row>
              </b-card-footer>
            </b-card>
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { Toast } from '../../components';
import { ajax, common, config, constant } from '../../utils';
import { api, helper } from '../common';

export default {
  data() {
    return {
      loading: true,
      upload_history: {},
      paymentSlipFiles: [],
      withholdingFiels: [],
      fields: [
        { key: 'ym', sortable: true, label: '対象年月' },
        { key: 'employee_name', sortable: true, label: '社員' },
        { key: 'is_sent', sortable: true, label: '通知済', class: 'text-center' },
        { key: 'file_name', sortable: true, label: 'ファイル名' },
      ],
      currentPage1: 1,
      currentPage2: 1,
      perPage: config.rowsPerPage,
    }
  },
  computed: {
    pageName: function() {
      return common.getPageName(this.$route.name);
    },
    pk() {
      return this.$route.params.id;
    },
    totalRows1: function() {
      return this.paymentSlipFiles.length;
    },
    totalRows2: function() {
      return this.withholdingFiels.length;
    },
    canResendAll: function() {
      if (this.upload_history.is_all_sent === false) {
        return true;
      } else {
        return false;
      }
    },
  },
  created: function () {
    document.title = this.pageName;
    this.getUploadDetail();
  },
  methods: {
    async getUploadDetail() {
      const vm = this;
      await ajax.fetchGet(common.formatStr(config.api.expense.upload_detail, this.pk)).then(data => {
        vm.upload_history = data;
      });
      await ajax.fetchGet(common.formatStr(config.api.expense.upload_detail_payment_slip, this.pk)).then(data => {
        vm.paymentSlipFiles = data;
      });
      await ajax.fetchGet(common.formatStr(config.api.expense.upload_detail_withholding, this.pk)).then(data => {
        vm.withholdingFiels = data;
      });
      this.loading = false;
    },
    showDatetime: function(d) {
      return common.formatDate(new Date(d), 'YYYY/MM/DD hh:mm')
    },
    previewPdf(uuid) {
      api.viewAttachment(uuid, this);
    },
    resend() {
      if (confirm(constant.CONFIRM.RESEND)) {
        const vm = this;
        common.loading();
        ajax.fetchPost(common.formatStr(config.api.expense.upload_detail_resend, this.pk)).then(() => {
          vm.getUploadDetail();
          Toast.success(constant.SUCCESS.RESENT);
        }).catch(errors => {
          helper.show_errors(errors);
        }).finally(() => {
          common.loaded();
        });
      }
    },
  },
}
</script>